import { useAlert } from 'react-alert';
import { useForm } from "react-hook-form";
import { API_URL } from 'src/API/CommonAPI';
import MiddleService from '../API/MiddleService';
import React, { useEffect, useState, useRef } from "react";
import { dateFormate, getUserId } from '../Service/service';

const Profile = () => {
    const alert = useAlert();
    const fileInputRef = useRef(null);

    const [isLoading, setLoading] = useState(false);
    const [mediaURL, setMediaURL] = useState(false);
    const [binaryData, setBinaryData] = useState('');
    const [binaryDataImage, setBinaryDataImage] = useState('');

    const {
        register,
        handleSubmit,
        reset,
        setValue,
        watch,
        formState: { errors }
    } = useForm();

    useEffect(() => {
        fetchUserProfile();
    }, [])

    const fetchUserProfile = async () => {
        setLoading(true);
        try {
            const payload = { "id": await getUserId() }
            const response = await MiddleService.postData('user/getUserById', payload);

            if (response.status_code === 200) {
                const { country_code, first_name, last_name, profile_image, full_name, mobile_no, email, id } = response?.data;
                setValue('country_code', country_code);
                setValue('full_name', full_name);
                setValue('first_name', first_name);
                setValue('last_name', last_name);
                setValue('mobile', mobile_no);
                setValue('email', email);
                setValue('id', id);
                if (profile_image) {
                    localStorage.setItem('profile_image', `${API_URL}/uploads/${profile_image}`);
                    setMediaURL(`${API_URL}/uploads/${profile_image}`);
                } else {
                    setMediaURL(null)
                }
            } else {
                alert.error('Something Went Wrong !!');
            }
        } catch (error) {
            console.error(error);
            alert.error('Something Went Wrong !!');
        }
        setLoading(false);
    };

    const onSubmit = async (data) => {
        const { country_code, full_name, mobile, first_name, last_name } = data;

        setLoading(true);
        try {
            const formData = new FormData()
            formData.append("full_name", full_name)
            formData.append("first_name", first_name)
            formData.append("last_name", last_name)
            formData.append("mobile_no", mobile)
            formData.append("country_code", country_code)
            formData.append("profile_image", binaryData)
            const response = await MiddleService.postData('user/updateUserProfile', formData);

            if (response.status_code === 200) {
                reset();
                await localStorage.setItem('profile_image', `${API_URL}/uploads/${binaryDataImage}`);
                window.location.replace('/app/profile');
                alert.success(response?.message);
            } else {
                alert.error(response?.message);
            }
        } catch (error) {
            console.error(error);
            alert.error('Something Went Wrong !!');
        }
        clearData();
        fetchUserProfile();
        setLoading(false);
    };

    const uploadImage = (e) => {
        let file = e.target.files;

        if (file?.length > 0) {
            setBinaryData(file[0])
            setBinaryDataImage(URL.createObjectURL(file[0]));
        } else {
            setBinaryData("")
        }
    }

    const clearData = () => {
        setBinaryData("")
        setBinaryDataImage("");
    }

    return (
        <>
            <div class="content-wrapper">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div className="d-flex justify-content-between">
                                    <h4 className="whatsapp-title">Profile</h4>
                                </div>
                                <div>
                                    <form class="pt-3" onSubmit={handleSubmit(onSubmit)}>
                                        <div className='justify-content-center'>
                                            <div class="form-group d-grid text-center" style={{ position: 'relative' }}>
                                                <input
                                                    type="file"
                                                    className="form-control"
                                                    onChange={uploadImage}
                                                    ref={fileInputRef}
                                                    style={{ display: 'none', marginBottom: 0 }}
                                                    id="fileInput"
                                                />
                                                <label htmlFor="fileInput">
                                                    <img className='profile-img mb-0' src={binaryDataImage || mediaURL || '/images/faces/profileLogo.jpeg'} alt="Click to upload" />
                                                </label>
                                                <label className='cursor-pointer edit-icon-profile' htmlFor="fileInput"><i htmlFor="fileInput" className='fa fa-edit' ></i></label>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <label>First Name</label>
                                                    <input
                                                        {...register("first_name", {
                                                            required: 'First Name is requried',
                                                        })}
                                                        class="form-control form-control-lg" placeholder="Enter First Name" />
                                                    {errors?.first_name && <p className="errorMessage">{errors?.first_name?.message}</p>}
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <label>Last Name</label>
                                                    <input
                                                        {...register("last_name", {
                                                            required: 'Last Name is requried',
                                                        })}
                                                        class="form-control form-control-lg" placeholder="Enter Last Name" />
                                                    {errors?.last_name && <p className="errorMessage">{errors?.full_name?.message}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <label>Mobile Number</label>
                                                    <div className='d-flex'>
                                                        <span style={{ marginRight: 8, width: 155 }} className='d-flex'>
                                                            <span className='' style={{ marginTop: 15, marginRight: 5 }}>+</span>
                                                            <span>
                                                                <input
                                                                    {...register("country_code", {
                                                                        required: 'Country code is requried',
                                                                        pattern: {
                                                                            value: /^[0-9]+$/,
                                                                            message: 'Invalid Code, please enter only code',
                                                                        },
                                                                    })}
                                                                    class="form-control form-control-lg" placeholder="Code" />
                                                                {errors?.country_code && <p className="errorMessage">{errors?.country_code?.message}</p>}
                                                            </span>
                                                        </span>
                                                        <span className='w-100'>
                                                            <input
                                                                {...register("mobile", {
                                                                    required: 'Mobile No is requried',
                                                                    pattern: {
                                                                        value: /^[0-9]{10}$/,
                                                                        message: 'Invalid mobile number',
                                                                    },
                                                                })}
                                                                class="form-control form-control-lg" placeholder="Contact No" />
                                                            {errors?.mobile && <p className="errorMessage">{errors?.mobile?.message}</p>}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <label>Email</label>
                                                    <input
                                                        {...register("email", {
                                                            required: 'Email is requried',
                                                        })}
                                                        disabled
                                                        class="form-control form-control-lg" placeholder="Enter Email" />
                                                    {errors?.email && <p className="errorMessage">{errors?.email?.message}</p>}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className='row'>
                                            <div className='col-md-6'>
                                                <div class="form-group">
                                                    <label>Email</label>
                                                    <input
                                                        {...register("email", {
                                                            required: 'Email is requried',
                                                        })}
                                                        disabled
                                                        class="form-control form-control-lg" placeholder="Enter Email" />
                                                    {errors?.email && <p className="errorMessage">{errors?.email?.message}</p>}
                                                </div>
                                            </div>
                                        </div> */}
                                        <div class="mt-3 d-flex justify-content-between">
                                            <button class="btn btn-primary btn-rounded br-8 btn-fw" disabled={isLoading}>
                                                {
                                                    isLoading ?
                                                        <>
                                                            <span class="spinner-border spinner-border-sm"></span> Loading...
                                                        </>
                                                        : 'Save'
                                                }
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile;