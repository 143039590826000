import React, { useState } from "react";
import axios from 'axios';
import { useForm } from "react-hook-form";
import MiddleService from '../API/MiddleService';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { userType } from "src/API/CommonAPI";
import { generateRandomId } from "src/Service/service";

const ResellerLogin = () => {

    const alert = useAlert();
    const navigate = useNavigate();
    const location = useLocation();
    const userType = location.pathname == '/resellerlogin' ? 'admin' : 'customer';

    const [isLoading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm();


    const onSubmit = async (data) => {
        // alert(JSON.stringify(data));
        setLoading(true);
        try {
            const url = 'reseller/signin';
            const response = await MiddleService.postData(url, data);

            const { message } = response || {};

            if (response?.status_code === 200) {
                const { data, token } = response;
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                localStorage.setItem('whatsappAuth', `Bearer ${token}`);
                localStorage.setItem('userType', userType);
                localStorage.setItem('userName', data?.name);
                generateRandomId()
                alert.success(message);
                navigate('/app/dashboard');
            } else if (response?.status_code === 400) {
                alert.error(message);
            }
        } catch (error) {
            console.error(error);
            alert.error('Something Went Wrong !!');
        }
        setLoading(false);
    };

    return (
        <>
            <div class="container-scroller">
                <div class="container-fluid page-body-wrapper full-page-wrapper">
                    <div class="content-wrapper d-flex align-items-center auth px-0">
                        <div class="row w-100 mx-0">
                            <div class="col-lg-4 mx-auto">
                                <div class="auth-form-light text-left py-5 px-4 px-sm-5">
                                    <div class="brand-logo">
                                        <img src="https://www.fruxinfo.com/assets/img/fruxinfo-logo.png" alt="logo" />
                                    </div>
                                    <h4>Hello! let's get started</h4>
                                    <h6 class="font-weight-light">Sign in to continue.</h6>
                                    <form class="pt-3" onSubmit={handleSubmit(onSubmit)}>
                                        <div class="form-group">
                                            <input
                                                {...register("username", {
                                                    required: true,
                                                })}
                                                class="form-control form-control-lg" id="exampleInputEmail1" placeholder="Username" />
                                            {errors?.username?.type === "required" && <p className="errorMessage">This field is required</p>}
                                        </div>
                                        <div class="form-group">
                                            <input
                                                {...register("password", {
                                                    required: true,
                                                    minLength: 6,
                                                })}
                                                type="password" class="form-control form-control-lg" id="exampleInputPassword1" placeholder="Password" />
                                            {errors?.password?.type === "required" && <p className="errorMessage">This field is required</p>}
                                            {errors?.password?.type === "minLength" && <p className="errorMessage">Password cannot minimum 6 characters</p>}
                                        </div>
                                        <div class="mt-3 section-login">
                                            <button class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" disabled={isLoading}>
                                                {
                                                    isLoading ?
                                                        <>
                                                            <span class="spinner-border spinner-border-sm"></span> Loading...
                                                        </>
                                                        : 'SIGN IN'
                                                }
                                            </button>
                                            <div>
                                                <Link to="/register" className="createAccount" >Sign Up</Link>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: 5 }} className="justify-content-space-between">
                                            <Link to="/forgotPasswordMobile" className="createAccount" >Forgot Password?</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResellerLogin;