import React from "react";

const AddUser = () => {
    return (
        <>
            AddUser
        </>
    )
}

export default AddUser;