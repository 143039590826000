export const userRoute = [
    {
        path: '/',
        exact: true,
        component: 'Dashboard',
        access: ['customer']
    },
    {
        path: '/dashboard',
        exact: true,
        component: 'Dashboard',
        access: ['customer']
    },
    {
        path: '/whatsapp/QR',
        exact: true,
        component: 'QRCodePage',
        access: ['customer']
    },
    {
        path: '/whatsappMessage',
        exact: true,
        component: 'WhatsappMessage',
        access: ['customer']
    },
    {
        path: '/whatsapp',
        exact: true,
        component: 'Whatsapp',
        access: ['customer']
    },
    {
        path: '/services',
        exact: true,
        component: 'Services',
        access: ['customer']
    },
    {
        path: '/user',
        exact: true,
        component: 'User',
        access: ['customer']
    },
    {
        path: '/changePassword',
        exact: true,
        component: 'ChangePassword',
        access: ['customer']
    },
    {
        path: '/recharge',
        exact: true,
        component: 'Recharge',
        access: ['customer']
    },
    {
        path: '/contact',
        exact: true,
        component: 'Contact',
        access: ['customer']
    },
    {
        path: '/group',
        exact: true,
        component: 'Group',
        access: ['customer']
    },
    {
        path: '/schedule',
        exact: true,
        component: 'Schedule',
        access: ['customer']
    },
    {
        path: '/schedule/add',
        exact: true,
        component: 'AddSchedule',
        access: ['customer']
    },
    {
        path: '/schedule/edit/:id',
        exact: true,
        component: 'AddSchedule',
        access: ['customer']
    },
    {
        path: '/message',
        exact: true,
        component: 'Message',
        access: ['customer']
    },
    {
        path: '/document',
        exact: true,
        component: 'Document',
        access: ['customer']
    },
    {
        path: '/profile',
        exact: true,
        component: 'Profile',
        access: ['customer']
    },
    {
        path: '/messagehistory',
        exact: true,
        component: 'MessageHistory',
        access: ['customer']
    },
];