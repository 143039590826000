import React from "react";
import { Link, useLocation } from "react-router-dom";
import { sidebarMenu } from './DaynamicSidebarMenu';
import { fetchUserType } from '../Service/service';

const Sidebar = () => {
    const location = useLocation();
    const userType = fetchUserType();

    return (
        <>
            <nav class="sidebar sidebar-offcanvas" id="sidebar">
                <ul class="nav">
                    {
                        sidebarMenu.map((item) => {
                            const { url, icon, title, activeShow, access, fullAccess } = item;

                            if (!access?.includes(userType)) {
                                return false;
                            }
                            if (localStorage.getItem("full_view") === "0" || localStorage.getItem("full_view") === "false") {
                                if (fullAccess === false) {
                                    return false
                                }
                            }

                            return (
                                <li class={`nav-item ${activeShow?.includes(location.pathname) ? 'active' : ''}`}>
                                {/* <li class={`nav-item ${activeShow?.some(item => location.pathname.includes(item)) ? 'active' : ''}`}> */}
                                    <Link class="nav-link" to={url}>
                                        <i class={icon}></i>
                                        <span class="menu-title">{title}</span>
                                    </Link>
                                </li>
                            )
                        })
                    }
                </ul>
            </nav>
        </>
    )
}

export default Sidebar;