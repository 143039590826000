import React, { useState } from "react";
import axios from 'axios';
import { useForm } from "react-hook-form";
import MiddleService from '../API/MiddleService';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { API_URL, userType } from "src/API/CommonAPI";
import { countryCode, generateRandomId } from "src/Service/service";

const Login = () => {

    const alert = useAlert();
    const navigate = useNavigate();
    const location = useLocation();
    const userType = location.pathname == '/resellerlogin' ? 'admin' : 'customer';

    const [isLoading, setLoading] = useState(false);
    const [newUser, setNewUser] = useState(false);
    const [step, setStep] = useState(1);
    const [code, setCode] = useState(91);
    const [number, setNumber] = useState('');

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm();


    const onSubmit = async (data) => {
        // alert(JSON.stringify(data));
        setLoading(true);
        try {
            const url = 'user/checkUser';
            const response = await MiddleService.postData(url, data);

            const { message } = response || {};

            if (response?.status_code === 200) {
                const { is_account_exist } = response;
                console.log("data?.is_account_exist::", is_account_exist)
                if (is_account_exist && is_account_exist === true) {
                    setStep(1)
                } else if (is_account_exist === false) {
                    setNewUser(true)
                }
            } else if (response?.status_code === 400) {
                if (response?.is_verify_otp === false) {
                    // const { id, mobile_no } = response;
                    // navigate('/registerOTP', { state: { mobile_no, user_id: id } });
                } else {
                    alert.error(message);
                }
            }
        } catch (error) {
            console.error(error);
            alert.error('Something Went Wrong !!');
        }
        setLoading(false);
    };

    const onSubmitLogin = async (data) => {
        // alert(JSON.stringify(data));
        setLoading(true);
        try {
            const url = 'user/signin';
            const response = await MiddleService.postData(url, data);

            const { message } = response || {};

            if (response?.status_code === 200) {
                const { data, token, is_full_access } = response;
                console.log("response::", response)
                localStorage.setItem('userName', data?.name);
                axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                localStorage.setItem('whatsappAuth', `Bearer ${token}`);
                localStorage.setItem('userType', userType);
                localStorage.setItem('full_view', is_full_access);
                if (data && data?.profile_image) {
                    localStorage.setItem('profile_image', `${API_URL}/uploads/${data?.profile_image}`);
                } else {
                    localStorage.setItem('profile_image', `/images/faces/profileLogo.jpeg`);
                }
                generateRandomId()
                alert.success(message);
                // navigate('/app/dashboard');
                window.location.replace('/app/dashboard');
                // setTimeout(() => {
                // }, 100);
            } else if (response?.status_code === 400) {
                if (response?.is_verify_otp === false) {
                    const { id, mobile_no } = response;
                    navigate('/registerOTP', { state: { mobile_no, user_id: id } });
                } else {
                    alert.error(message);
                }
            }
        } catch (error) {
            console.error(error);
            alert.error('Something Went Wrong !!');
        }
        setLoading(false);
    };

    const forgotPassword = async () => {
        const contact = number;

        setLoading(true);
        try {
            const response = await MiddleService.postData('user/sendForgotPasswodOtp', { "mobile_no": contact, "country_code": code });

            if (response.status_code === 200) {
                const { user_id } = response;
                navigate('/forgotPasswordOTP', { state: { mobile_no: contact, user_id } });
                // alert.success(response?.message);
            } else {
                alert.error(response?.message);
            }
        } catch (error) {
            console.error(error);
            alert.error('Something Went Wrong !!');
        }
        setLoading(false);
    };


    return (
        <>
            <div class="container-scroller">
                <div class="container-fluid page-body-wrapper full-page-wrapper">
                    <div class="content-wrapper d-flex align-items-center auth px-0">
                        <div class="row w-100 mx-0">
                            <div class="col-lg-4 mx-auto">
                                <div class="auth-form-light text-left py-5 px-4 px-sm-5">
                                    <div class="brand-logo text-center">
                                        <img src="/images/Fruxinfo_Logo.png" alt="logo" />
                                    </div>
                                    {/* <h4>Hello! let's get started</h4>
                                    <h6 class="font-weight-light">Sign in to continue.</h6> */}
                                    {step === 0 &&
                                        <form class="pt-3" onSubmit={handleSubmit(onSubmit)}>
                                            <div class="form-group">
                                                <label className="font-textbox">Enter Phone Number</label>
                                                <div className="text-flex">
                                                    <select
                                                        {...register("country_code", {
                                                            // required: true,
                                                        })}
                                                        disabled={newUser}
                                                        value={code} className="select-border" onChange={(e) => {
                                                            setCode(e.target.value)
                                                        }}>
                                                        {countryCode && countryCode.map((item, index) => (
                                                            <option key={index} selected={item.code == 91 ? true : false} value={item.code}>+{item.code}</option>
                                                        ))}
                                                    </select>
                                                    <input
                                                        {...register("username", {
                                                            required: true,
                                                        })}
                                                        onChange={(e) => setNumber(e.target.value)}
                                                        disabled={newUser}
                                                        class="form-control form-control-lg" id="exampleInputEmail1" placeholder="Enter phone number" />
                                                </div>
                                                {errors?.username?.type === "required" && <p className="errorMessage">This field is required</p>}
                                            </div>
                                            {/* <div class="form-group">
                                            <input
                                                {...register("password", {
                                                    required: true,
                                                    minLength: 6,
                                                })}
                                                type="password" class="form-control form-control-lg" id="exampleInputPassword1" placeholder="Password" />
                                            {errors?.password?.type === "required" && <p className="errorMessage">This field is required</p>}
                                            {errors?.password?.type === "minLength" && <p className="errorMessage">Password cannot minimum 6 characters</p>}
                                        </div> */}
                                            <div class="mt-3 section-login text-center">
                                                {!newUser ?
                                                    <button class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn text-center" disabled={isLoading}>
                                                        {
                                                            isLoading ?
                                                                <>
                                                                    <span class="spinner-border spinner-border-sm"></span> Loading...
                                                                </>
                                                                : 'Log In'
                                                        }
                                                    </button>
                                                    :
                                                    <div>
                                                        Are you a new user? <Link to="/register" state={{ code: code, mobile_no: number }} className="createAccount" >Ceate an account</Link>
                                                    </div>}
                                            </div>
                                            {/* <div style={{ marginTop: 5 }} className="justify-content-space-between">
                                            <Link to="/forgotPasswordMobile" className="createAccount" >Forgot Password?</Link>
                                        </div> */}
                                        </form>
                                    }
                                    {step === 1 &&
                                        <form class="pt-3" onSubmit={handleSubmit(onSubmitLogin)}>
                                            <div class="form-group">
                                                <label className="font-textbox">Enter Phone Number</label>
                                                <div className="text-flex">
                                                    <select className="select-border"
                                                    {...register("country_code", {
                                                        required: true,
                                                    })}
                                                    >
                                                        {countryCode && countryCode.map((item, index) => (
                                                            <option key={index} selected={item.code == 91 ? true : false} value={item.code}>+{item.code}</option>
                                                        ))}
                                                    </select>
                                                    <input
                                                        {...register("username", {
                                                            required: true,
                                                        })}
                                                        class="form-control form-control-lg" id="exampleInputEmail1" placeholder="Enter phone number" />
                                                </div>
                                                {errors?.username?.type === "required" && <p className="errorMessage">This field is required</p>}
                                            </div>
                                            <div class="form-group">
                                                <input
                                                    {...register("password", {
                                                        required: true,
                                                        minLength: 6,
                                                    })}
                                                    type="password" class="form-control form-control-lg" id="exampleInputPassword1" placeholder="Password" />
                                                {errors?.password?.type === "required" && <p className="errorMessage">This field is required</p>}
                                                {errors?.password?.type === "minLength" && <p className="errorMessage">Password cannot minimum 6 characters</p>}
                                                <div style={{ marginTop: 5, textAlign: 'right' }} className="">
                                                    <Link to="/forgotPasswordMobile" className="createAccount" >Forgot Password?</Link>
                                                </div>
                                            </div>
                                            <div class="mt-3 section-login text-center">
                                                <button class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn text-center" disabled={isLoading}>
                                                    {
                                                        isLoading ?
                                                            <>
                                                                <span class="spinner-border spinner-border-sm"></span> Loading...
                                                            </>
                                                            : 'SIGN IN'
                                                    }
                                                </button>
                                                {/* <div>
                                                <Link to="/register" className="createAccount" >Sign Up</Link>
                                            </div> */}
                                            </div>
                                            <br />
                                            <div className="text-center">
                                                Are you a new user? <Link to="/register" className="createAccount" >Ceate an account</Link>
                                            </div>
                                        </form>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;