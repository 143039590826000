import axios from 'axios';
import React, { useState, useEffect } from "react";
import { useAlert } from 'react-alert';
import { useForm } from "react-hook-form";
import MiddleService from '../API/MiddleService';
import { useNavigate, useLocation } from 'react-router-dom';
const ForgotPassword = () => {

    const alert = useAlert();
    const navigate = useNavigate();
    const location = useLocation();
    const navigationData = location.state;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    
    const [isLoading, setLoading] = useState(false);

    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors }
    } = useForm();

    const password = watch('password');
    const isPasswordMatch = (value) => value === password || 'Passwords do not match.';

    useEffect(() => {
        if (!navigationData?.user_id) {
            navigate('/')
        }
    }, [navigationData])

    const onSubmit = async (data) => {
        const { rePassword, password } = data;

        setLoading(true);
        // navigate('/');
        try {
            const payload = {
                "user_id": navigationData?.user_id,
                "password": password,
            }
            // reset();
            const response = await MiddleService.postData('user/chnagePassword', payload);
            if (response) {
                navigate('/');
                reset();
                alert.success("Message send succefully");
            }
        } catch (error) {
            console.error(error);
            alert.error('Something Went Wrong !!');
        }
        setLoading(false);
    };

    return (
        <>
            <div class="container-scroller">
                <div class="container-fluid page-body-wrapper full-page-wrapper">
                    <div class="content-wrapper d-flex align-items-center auth px-0">
                        <div class="row w-100 mx-0">
                            <div class="col-lg-4 mx-auto">
                                <div class="auth-form-light text-left py-5 px-4 px-sm-5">
                                    <div class="brand-logo text-center">
                                        <img src="/images/Fruxinfo_Logo.png" alt="logo" />
                                    </div>
                                    <h6 class="font-weight-light">Forgot Password</h6>
                                    <form class="pt-3" onSubmit={handleSubmit(onSubmit)}>
                                        <div class="form-group">
                                            <div className="w-100">
                                                <input type="password"
                                                    {...register("password", {
                                                        required: 'Password is requried',
                                                        pattern: {
                                                            value: passwordRegex,
                                                            message:
                                                                'Password must be at least 6 characters long, contain one uppercase letter, one lowercase letter, and one special symbol.',
                                                        },
                                                    })}
                                                    class="form-control form-control-lg" placeholder="Enter New Password" />
                                                {errors?.password && <p className="errorMessage">{errors?.password?.message}</p>}
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div className="w-100">
                                                <input type="password"
                                                    {...register("rePassword", {
                                                        required: 'Password is requried',
                                                        validate: isPasswordMatch,
                                                    })}
                                                    class="form-control form-control-lg" placeholder="Reenter New Password" />
                                                {errors?.rePassword && <p className="errorMessage">{errors?.rePassword?.message}</p>}
                                            </div>
                                        </div>
                                        <div class="mt-3 section-login">
                                            <button class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" disabled={isLoading}>
                                                {
                                                    isLoading ?
                                                        <>
                                                            <span class="spinner-border spinner-border-sm"></span> Loading...
                                                        </>
                                                        : 'Save'
                                                }
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword;