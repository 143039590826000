export const sidebarMenu = [
    {
        url: "/app/dashboard",
        icon: "icon-grid menu-icon",
        title: "Dashboard",
        fullAccess: true,
        activeShow: ["/app", "/app/dashboard"],
        access: ['customer']
    },
    {
        url: "/app/whatsapp/QR",
        icon: "icon-cog menu-icon",
        title: "Whatsapp",
        fullAccess: true,
        activeShow: ["/app/whatsapp/QR", "/app/whatsappMessage"],
        access: ['customer']
    },
    {
        url: "/app/contact",
        icon: "icon-cog  menu-icon",
        title: "Contact List",
        fullAccess: false,
        activeShow: ["/app/contact"],
        access: ['customer']
    },
    {
        url: "/app/group",
        icon: "icon-cog  menu-icon",
        title: "Group List",
        fullAccess: false,
        activeShow: ["/app/group"],
        access: ['customer']
    },
    {
        url: "/app/schedule",
        icon: "icon-cog  menu-icon",
        title: "Schedule",
        fullAccess: false,
        activeShow: ["/app/schedule", "/app/schedule/add",  "/app/schedule/edit"],
        access: ['customer']
    },
    {
        url: "/app/message",
        icon: "icon-cog  menu-icon",
        title: "Message",
        fullAccess: false,
        activeShow: ["/app/message"],
        access: ['customer']
    },
    
    {
        url: "/app/messagehistory",
        icon: "icon-cog  menu-icon",
        title: "Message History",
        fullAccess: true,
        activeShow: ["/app/messagehistory"],
        access: ['customer']
    },
    {
        url: "/app/recharge",
        icon: "icon-cog  menu-icon",
        title: "Recharge History",
        fullAccess: true,
        activeShow: ["/app/recharge"],
        access: ['customer']
    },
    {
        url: "/app/document",
        icon: "icon-cog  menu-icon",
        title: "Document",
        fullAccess: false,
        activeShow: ["/app/document"],
        access: ['customer']
    },
];