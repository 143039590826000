import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AfterLoginRoute from './Route/AfterLoginRoute';
import BeforeLoginRoute from './Route/BeforeLoginRoute';
import { API_URL } from './API/CommonAPI';
import axios from 'axios';
import 'react-responsive-modal/styles.css';

function App() {
  const { hostname } = window.location;
  axios.defaults.baseURL = API_URL;
  axios.defaults.headers.common['domainName'] = 'fruxinfo';
  // parts[0]

  return (
    <Router>
      <Routes>
        <Route path="/app/*" element={<AfterLoginRoute />} />
        <Route path="/*" element={<BeforeLoginRoute />} />
      </Routes>
    </Router>
  );
}

export default App;
