import { useAlert } from 'react-alert';
import MiddleService from '../API/MiddleService';
import React, { useEffect, useState } from "react";
import { fetchUserName, getUserId, dateFormate } from '../Service/service';

const Dashboard = () => {

    const alert = useAlert();
    const [dashboardData, setDashboardData] = useState();

    useEffect(() => {
        fetchDashboardData();
    }, [])

    const fetchDashboardData = async () => {
        try {
            const payload = {
                "user_type": 3,
                "user_id": await getUserId(),
            };
            const response = await MiddleService.postData('dashboard', payload);
            if (response.status_code === 200) {
                const { data } = response;
                setDashboardData(data);
                if(data?.is_full_access != undefined) {
                    
                    if (localStorage.getItem("full_view") === String(data?.is_full_access) || localStorage.getItem("full_view") === String(data?.is_full_access)) {
                        
                    } else {
                        localStorage.setItem('full_view', data?.is_full_access);
                        window.location.reload()
                    }
                }
            } else {
                alert.error('Something Went Wrong !!');
            }
        } catch (error) {
            console.error(error);
            alert.error('Something Went Wrong !!');
        }
    };

    return (
        <>
            <div class="content-wrapper">
                <div class="row">
                    <div class="col-md-12 grid-margin">
                        <div class="row">
                            <div class="col-12 col-xl-8 mb-4 mb-xl-0">
                                <h3 class="font-weight-bold">Welcome {fetchUserName()}</h3>
                                {/* <h6 class="font-weight-normal mb-0">All systems are running smoothly! You have <span class="text-primary">3 unread alerts!</span></h6> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">

                    {
                        dashboardData?.is_month_credit ?
                            < div class="col-md-3 mb-4 stretch-card transparent">
                                <div class="card card-dark-blue">
                                    <div class="card-body">
                                        <p class="mb-4">Credit Experied At</p>
                                        <p class="fs-30 mb-2">{dateFormate(dashboardData?.credit_expired_at) || 0}</p>
                                    </div>
                                </div>
                            </div>
                            :
                            <>
                                <div class="col-md-3 mb-4 stretch-card transparent">
                                    <div class="card card-tale">
                                        <div class="card-body">
                                            <p class="mb-4">Today’s Use Credits</p>
                                            <p class="fs-30 mb-2">{dashboardData?.total_used_credit || 0}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 mb-4 stretch-card transparent">
                                    <div class="card card-dark-blue">
                                        <div class="card-body">
                                            <p class="mb-4">Total Remaning Credit</p>
                                            <p class="fs-30 mb-2">{dashboardData?.remain_credit || 0}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 mb-4 stretch-card transparent">
                                    <div class="card card-light-danger">
                                        <div class="card-body">
                                            <p class="mb-4">Total Use Credits</p>
                                            <p class="fs-30 mb-2">{dashboardData?.remain_month || 0}</p>

                                        </div>
                                    </div>
                                </div>
                            </>
                    }

                </div>
            </div >
        </>
    )
}

export default Dashboard;