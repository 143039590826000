import dayjs from 'dayjs';
const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const countryCode = [
  {
    name: "India",
    code: 91,
  },
  {
    name: "Canada",
    code: 1,
  },
  {
    name: "United Kingdom",
    code: 44,
  },
  {
    name: "Australia",
    code: 61,
  },
  {
    name: "New Zealand",
    code: 64,
  },
  {
    name: "Japan",
    code: 81,
  },
  {
    name: "Tanzania",
    code: 255,
  },
  {
    name: "United Arab Emirates",
    code: 971,
  },
]

export const generateRandomId = () => {
  let token = fetchAuthToken()
  const decodedJwt = parseJwt(token?.replace("Bearer ", ""));
  return localStorage.setItem("randomID", decodedJwt?.user_code)
};

// export const generateRandomId = () => {
//   const randomNumber = Math.floor(1000000 + Math.random() * 9000000);
//   return randomNumber.toString().substring(0, 7);
// };

export const fetchAuthToken = () => {
  return localStorage.getItem("whatsappAuth")
}

export const fetchUserType = () => {
  return localStorage.getItem("userType") || 'admin'
}

export const fetchUserName = () => {
  return localStorage.getItem("userName")
}

export const getUserId = () => {
  let token = fetchAuthToken()
  const decodedJwt = parseJwt(token?.replace("Bearer ", ""));
  return decodedJwt?.user_id
}

export const dateFormate = (customDate) => {
  if (!customDate) {
    return ""
  }
  return dayjs(customDate).format("DD-MM-YYYY")
}

export const dateTimeFormat = (customDate) => {
  if (!customDate) {
    return ""
  }
  return dayjs(customDate).format("DD-MM-YYYY hh:mm a")
}

export const dateCustomFormat = (date, format) => {
  return dayjs(date).format(format)
}

export const timeCustomFormat = (time) => {
  return dayjs(` 2023-09-12 ${time}`, 'YYYY-MM-DD hh:mm A').format("HH:mm")
}
export const timeCustomFormatAMPM = (time) => {
  return dayjs(` 2023-09-12 ${time}`, 'YYYY-MM-DD hh:mm A').format("hh:mm a")
}

// convertBase64 PDF
export const convertBase64PDF = (file) => {

  if (file?.length > 0) {
    return new Promise((resolve, reject) => {
      var fileReader = new FileReader();
      fileReader.readAsDataURL(file[0]);
      fileReader.onload = () => resolve(fileReader.result);
      fileReader.onerror = error => reject(error);
    });
  }
};