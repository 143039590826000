import React, { useEffect, useState, useRef } from "react";
import MiddleService from '../API/MiddleService';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useAlert } from 'react-alert';

const ForgotPasswordOTP = () => {

    const alert = useAlert();
    const navigate = useNavigate();
    const location = useLocation();
    const navigationData = location.state;

    const [isLoading, setLoading] = useState(false);
    const [seconds, setSeconds] = useState(15);
    const [intervalRef, setIntervalRef] = useState('');
    // const [otp, setOtp] = useState('');
    const [otp, setOtp] = useState(['', '', '', '']);
    const inputRefs = useRef([]);

    useEffect(() => {
        if (!navigationData?.mobile_no) {
            navigate('/')
        } else {
            // handlesendOTP({ mobile_no: navigationData?.mobile_no });
        }

        return (() => {
            clearInterval(intervalRef);
        })
    }, [navigationData])

    useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => {
                setSeconds(seconds - 1);
            }, 1000);
        }
    }, [seconds])

    const handlesendOTP = async ({ mobile_no }) => {

        const payload = {
            "mobile_no": mobile_no,
            "country_code": 91
        }

        try {
            const response = await MiddleService.postData('/user/sendOtp', payload);
            const { message } = response || {};
            if (response?.status_code === 200) {
                // setOtp([response?.otp[0], response?.otp[1], response?.otp[2], response?.otp[3]]);
                alert.success(message);
                setSeconds(15);
                // setIntervalRef(setInterval(() => {
                //     setSeconds((prevSeconds) => prevSeconds - 1);
                // }, 1000));
            } else if (response?.status_code === 400) {
                alert.error(message);
            }
        } catch (error) {
            console.error(error);
            alert.error('Something Went Wrong !!');
        }
    }

    const handleOtpChange = (index, event) => {
        const newOtp = [...otp];
        newOtp[index] = event.target.value;

        // Move focus to the next input field
        if (event.target.value !== '' && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }

        setOtp(newOtp);
    };

    const handleOtpBackspace = (index, event) => {
        const newOtp = [...otp];
        newOtp[index] = '';

        // Move focus to the previous input field
        if (index > 0) {
            inputRefs.current[index - 1].focus();
        }

        setOtp(newOtp);
    };

    const handleOtpPaste = (event) => {
        const pasteData = event.clipboardData.getData('text/plain');

        // Extract digits from pasted data
        const digits = pasteData.split('').filter((char) => !isNaN(parseInt(char, 10)));

        // Update OTP digits
        const newOtp = [...otp];
        digits.forEach((digit, index) => {
            if (index < newOtp.length) {
                newOtp[index] = digit;
            }
        });

        setOtp(newOtp);
    };

    const handleChange = (e) => {
        const { value } = e.target;
        setOtp(value);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const payload = {
            "user_id": navigationData?.user_id,
            "otp": otp.join('')
        }

        try {
            const response = await MiddleService.postData('/user/verifyForgotPasswodOtp', payload);
            const { message } = response || {};
            if (response?.status_code === 200) {
                alert.success(message);
                navigate('/forgotPassword', { state: { user_id: navigationData?.user_id } });
            }
            else if (response?.status_code === 400) {
                alert.error(message);
            }
        } catch (error) {
            console.error(error);
            alert.error('Something Went Wrong !!');
        }
        setLoading(false);

    };

    return (
        <>
            <div class="container-scroller">
                <div class="container-fluid page-body-wrapper full-page-wrapper">
                    <div class="content-wrapper d-flex align-items-center auth px-0">
                        <div class="row w-100 mx-0">
                            <div class="col-lg-4 mx-auto">
                                <div style={{ marginBottom: 5 }}>
                                    <Link to="/" className="createAccount mt-5" >
                                        <i class="fa fa-chevron-left" aria-hidden="true" style={{ marginRight: 5 }}></i>
                                        Back
                                    </Link>
                                </div>
                                <div class="auth-form-light text-left py-5 px-4 px-sm-5">
                                    <div class="brand-logo text-center">
                                        <img src="/images/Fruxinfo_Logo.png" alt="logo" />
                                    </div>
                                    <h4>OTP Sent In Your Register Email ID</h4>
                                    <h6 class="font-weight-light">OTP to continue.</h6>
                                    <form class="pt-3" onSubmit={onSubmit}>
                                        <div className="d-flex space-evenly">
                                            {otp?.length && otp?.map((digit, index) => (
                                                <div class="form-group">
                                                    <input
                                                        key={index}
                                                        ref={(ref) => (inputRefs.current[index] = ref)}
                                                        type="number"
                                                        value={digit}
                                                        class="form-control form-control-lg otp-input"
                                                        onChange={(event) => handleOtpChange(index, event)}
                                                        onKeyDown={(event) => {
                                                            if (event.key === 'Backspace' && digit === '') {
                                                                handleOtpBackspace(index, event);
                                                            }
                                                        }}
                                                        onPaste={handleOtpPaste}
                                                        maxLength={1}
                                                    // style={{ width: '50px', marginRight: '10px', padding:0 }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                        {/* <div class="form-group"> */}
                                        {/* <input type="number" class="form-control form-control-lg" name="otp" placeholder="OTP" value={otp} onChange={handleChange} /> */}
                                        <div>
                                            {
                                                seconds ?
                                                    <>
                                                        <div className="createAccount">Resend OTP In {seconds}</div>
                                                    </>
                                                    : <div className="createAccount cursor-pointer" onClick={() => handlesendOTP({ mobile_no: navigationData?.mobile_no })}>Resend OTP</div>
                                            }
                                        </div>
                                        <div class="mt-3 section-login">
                                            <button class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" disabled={isLoading}>
                                                {
                                                    isLoading ?
                                                        <>
                                                            <span class="spinner-border spinner-border-sm"></span> Loading...
                                                        </>
                                                        : 'Forgot Password'
                                                }
                                            </button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPasswordOTP;