import { useAlert } from 'react-alert';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import MiddleService from '../API/MiddleService';
import React, { useRef, useState } from "react";
import { countryCode } from 'src/Service/service';
import { useEffect } from 'react';

const Register = () => {

    const alert = useAlert();
    const navigate = useNavigate();
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;

    const [isLoading, setLoading] = useState(false);
    const [isHide, setIsHide] = useState({ rePassword: true, password: true });
    const [code, setCode] = useState(91);
    const [number, setNumber] = useState('');
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm();

    const password = watch('password');
    const isPasswordMatch = (value) => value === password || 'Passwords do not match.';


    const location = useLocation();
    const paramsLeaveStatus = location?.state?.code || null;
    const paramsLeavemobile_no = location?.state?.mobile_no || null;

    useEffect(() => {
        if(paramsLeaveStatus) {
            setCode(paramsLeaveStatus)
        }
        if(paramsLeavemobile_no) {
            setNumber(paramsLeavemobile_no)
        }
    }, [paramsLeaveStatus, paramsLeavemobile_no])
    

    const onSubmit = async (data) => {

        const { userFname, userLname, contact, email, country_code } = data;

        setLoading(true);
        try {
            const payload = {
                "id": '',
                "first_name": userFname,
                "last_name": userLname,
                "email": email,
                "mobile_no": contact,
                "country_code": country_code ? Number(country_code) : '',
                "addresse": "",
                "password": password
            }

            const API_URL = 'user/signup'
            const response = await MiddleService.postData(API_URL, payload);

            if (response.status_code === 200) {
                const { id } = response;
                navigate('/registerOTP', { state: { mobile_no: contact, user_id: id } });
                alert.success(response?.message);
            } else {
                alert.error(response?.message);
            }
        } catch (error) {
            console.error(error);
            alert.error('Something Went Wrong !!');
        }
        setLoading(false);
    };

    const toggleEyeButton = (name) => {
        setIsHide((prev) => ({
            ...prev,
            [name]: !prev[name]
        }))
    }

    return (
        <>
            <div class="container-scroller">
                <div class="container-fluid page-body-wrapper full-page-wrapper">
                    <div class="content-wrapper d-flex align-items-center auth px-0">
                        <div class="row w-100 mx-0">
                            <div class="col-lg-4 mx-auto">
                                <div style={{ marginBottom: 5 }}>
                                    <Link to="/" className="createAccount mt-5" >
                                        <i class="fa fa-chevron-left" aria-hidden="true" style={{ marginRight: 5 }}></i>
                                        Back
                                    </Link>
                                </div>
                                <div class="auth-form-light text-left py-5 px-4 px-sm-5">
                                    <div class="brand-logo text-center">
                                        <img src="/images/Fruxinfo_Logo.png" alt="logo" />
                                    </div>
                                    <div className="font-textbox text-center">
                                        <label className="font-textbox text-center">Create your account</label>
                                    </div>
                                    <form class="pt-3" onSubmit={handleSubmit(onSubmit)}>
                                        <div class="form-group">
                                            <input
                                                {...register("userFname", {
                                                    required: 'First Name is requried',
                                                })}
                                                class="form-control form-control-lg" placeholder="Enter User First Name" />
                                            {errors?.userFname && <p className="errorMessage">{errors?.userFname?.message}</p>}
                                        </div>
                                        <div class="form-group">
                                            <input
                                                {...register("userLname", {
                                                    required: 'Last Name is requried',
                                                })}
                                                class="form-control form-control-lg" placeholder="Enter User Last Name" />
                                            {errors?.userLname && <p className="errorMessage">{errors?.userLname?.message}</p>}
                                        </div>
                                        <div class="form-group d-flex">
                                            <span style={{ marginRight: 8 }} className='d-flex'>
                                                <select
                                                    {...register("country_code", {
                                                        required: true,
                                                    })}
                                                    value={code} className="select-border" onChange={(e) => {
                                                        setCode(e.target.value)
                                                    }}>
                                                    {countryCode && countryCode.map((item, index) => (
                                                        <option key={index} selected={item.code == 91 ? true : false} value={item.code}>+{item.code}</option>
                                                    ))}
                                                </select>
                                            </span>
                                            <span className='w-100'>
                                                <input
                                                    {...register("contact", {
                                                        required: 'Contact No is requried',
                                                        pattern: {
                                                            value: /^[0-9]{10}$/,
                                                            message: 'Invalid contact number',
                                                        },
                                                    })}
                                                    // value={number}
                                                    class="form-control form-control-lg" placeholder="Contact No" />
                                                {errors?.contact && <p className="errorMessage">{errors?.contact?.message}</p>}
                                            </span>
                                        </div>
                                        <div class="form-group">
                                            <input
                                                {...register("email", {
                                                    required: 'Email is requried',
                                                    pattern: {
                                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                                        message: 'Invalid email address',
                                                    },
                                                })}
                                                class="form-control form-control-lg" placeholder="Email" />
                                            {errors?.email && <p className="errorMessage">{errors?.email?.message}</p>}
                                        </div>
                                        <div class="form-group d-flex">
                                            <div className="w-100">
                                                <input type={isHide?.password ? "password" : "text"}
                                                    {...register("password", {
                                                        required: 'Password is requried',
                                                        pattern: {
                                                            value: passwordRegex,
                                                            message:
                                                                'Password must be at least 6 characters long, contain one uppercase letter, one lowercase letter, and one special symbol.',
                                                        },
                                                    })}
                                                    class="form-control form-control-lg" placeholder="Enter New Password" />
                                                {errors?.password && <p className="errorMessage">{errors?.password?.message}</p>}
                                            </div>
                                            <div className="eye-icon-copy-icon">
                                                <div title={`${isHide?.password ? 'Show' : 'Hide'} Password`}>
                                                    {
                                                        isHide?.password ?
                                                            <i className="fa fa-eye-slash cursor-pointer" onClick={() => toggleEyeButton('password')}></i>
                                                            :
                                                            <i className="fa fa-eye cursor-pointer" onClick={() => toggleEyeButton('password')}></i>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group d-flex">
                                            <div className="w-100">
                                                <input type={isHide?.rePassword ? "password" : "text"}
                                                    {...register("rePassword", {
                                                        required: 'Password is requried',
                                                        validate: isPasswordMatch,
                                                    })}
                                                    class="form-control form-control-lg" placeholder="Reenter New Password" />
                                                {errors?.rePassword && <p className="errorMessage">{errors?.rePassword?.message}</p>}
                                            </div>
                                            <div className="eye-icon-copy-icon">
                                                <div title={`${isHide?.rePassword ? 'Show' : 'Hide'} Password`}>
                                                    {
                                                        isHide?.rePassword ?
                                                            <i className="fa fa-eye-slash cursor-pointer" onClick={() => toggleEyeButton('rePassword')}></i>
                                                            :
                                                            <i className="fa fa-eye cursor-pointer" onClick={() => toggleEyeButton('rePassword')}></i>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-3 d-flex justify-content-center">
                                            <button class="btn btn-primary btn-rounded br-8 btn-fw" disabled={isLoading} style={{ marginRight: 5 }}>
                                                {
                                                    isLoading ?
                                                        <>
                                                            <span class="spinner-border spinner-border-sm"></span> Loading...
                                                        </>
                                                        : 'Register'
                                                }
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Register;