import React, { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MiddleService from '../API/MiddleService';
import { useAlert } from 'react-alert';
import { fetchAuthToken, generateRandomId } from '../Service/service';
import Loader from '../Components/Loader';
import io from 'socket.io-client';
import { socket_url } from '../API/CommonAPI';
import FallbackLoader from "../Components/FallbackLoader";
import dayjs from "dayjs";

const getLocalStorage = localStorage.getItem('randomID');
const randomId = fetchAuthToken() //getLocalStorage || generateRandomId();

// localStorage.setItem('randomID', randomId);

// let socket = null
let socket = io(`${socket_url}?user_temp=${randomId}&user_code=${localStorage.getItem('randomID')}`, {
    transports: ["websocket"],
    // transports: ['websocket', 'polling', 'flashsocket'],
});
const QRCodePage = () => {

    const alert = useAlert();
    const [qrCode, setQRCode] = useState('');
    const [qrCodeList, setQRCodeList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        checkUserLogin();
        socketConnection()
    }, []);
   
    const socketConnection = async () => {
        
    }

    const checkUserLogin = async () => {
        setIsLoading(true);
        try {
            const response = await MiddleService.getData('user/checkLoginStatus');
            if (response.status_code === 200) {
                const { data } = response;
                if (data?.is_login) {
                    navigate('/app/whatsappMessage', { state: { secreatKey: data?.api_key } });
                } else {
                    callSocket();
                }

            } else {
                alert.error(response?.message);
            }
        } catch (error) {
            console.error(error);
            alert.error('Something Went Wrong !!');
        }
        setIsLoading(false);
    }

    const callWhatsappCode = () => {
        if(localStorage.getItem("code_time")) {
            const oldTime = dayjs(localStorage.getItem("code_time"))
            const currentTime = dayjs(new Date()).format("YYYY-MM-DD hh:mm:ss");
            const currentT = dayjs(new Date())

            const mins = currentT.diff(oldTime, "minutes");
            console.log("mins::", mins)
            if (mins >= 10) {
                localStorage.setItem("code_time", String(currentTime))
                socket.emit('whatsapp_code', fetchAuthToken());
            }
        } else {
            const currentTime = dayjs().format("YYYY-MM-DD hh:mm:ss");
            localStorage.setItem("code_time", String(currentTime))
            socket.emit('whatsapp_code', fetchAuthToken());
        }
    }

    const callSocket = () => {
        fetchQRData()
        
        // socket.emit('whatsapp_code', fetchAuthToken());
        socket.on('whatsapp_code', (data) => {
            callAPI(data);
        });

        socket.on('whatsapp_ready', (data) => {
            checkUserLogin();
            // const reponse = data ? JSON.parse(data) : '';
            // navigate('/app/whatsappMessage', { state: { secreatKey: reponse?.api_key } });
        });
    }

    const callAPI = (response) => {
        if (response) {
            setQRCode(response);
            const qrList = document.getElementById("qrList")
            const node = document.createElement("li");
            const textnode = document.createTextNode(response);
            node.appendChild(textnode);

            qrList.appendChild(node)
            // let dataQr = qrCodeList
            // dataQr.push(response)
            // setQRCodeList(dataQr)
        }
    }

    useEffect(() => {
        socket.on('whatsapp_logout', (data) => {
            localStorage.removeItem("code_time")
            window.location.reload();
        });
    }, []);


    const fetchQRData = async () => {
        try {
            const response = await MiddleService.getData('whatsapp/getQrCode');
            if (response.status_code === 200) {
                const { qr } = response;
                if(qr == "") {
                    callWhatsappCode()
                }
                setQRCode(qr);
            } else {
                callWhatsappCode()
                alert.error('Something Went Wrong !!');
            }
        } catch (error) {
            console.error(error);
            callWhatsappCode()
            alert.error('Something Went Wrong !!');
        }
    };


    return (
        <>
            {
                isLoading ?
                    <>
                        <FallbackLoader message="Loading..." />
                    </>
                    :
                    <div class="content-wrapper">
                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-body">
                                        <div className="row">
                                            <div className="col-md-8">
                                                <h4 onClick={() => { callAPI(11112) }} class="whatsapp-title">To use WhatsApp on your computer.</h4>
                                                <ol id="qrList" className="mt-5 whatsapp-QR">
                                                    <li>Open <b>Whastapp</b> on your phone</li>
                                                    <li>Tap <b>Menu</b> <span className="backgroud-gray"><i className="fa fa-ellipsis-v"></i></span> or <b>Setting</b> <span className="backgroud-gray"><i className="fa fa-cog"></i></span> and select WhatsApp Web</li>
                                                    <li>Point your phone to this screen to captue the code</li>
                                                    {/* {qrCodeList && qrCodeList.map((item) => (
                                                        <li>{item}</li>
                                                    ))} */}
                                                </ol>
                                                {/* <Link class="nav-link" state={{ secreatKey: 'ABCD' }} to="/app/whatsappMessage">
                                            <div className="pt-2 ">
                                                <button type="button" class="btn btn-primary btn-rounded btn-fw">Connect Whatsapp</button>
                                            </div>
                                        </Link> */}
                                            </div>
                                            <div className="col-md-4 centerdiv">
                                                {
                                                    qrCode ? <QRCode className="img_qr_reffrela p-3" value={qrCode} />
                                                        :
                                                        <Loader message="QR Code is Loading..." />
                                                }
                                            </div>
                                            
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            }
        </>
    )
}

export default QRCodePage;