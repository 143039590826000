import React, { useState } from "react";
import { useAlert } from 'react-alert';
import { useForm } from "react-hook-form";
import MiddleService from '../API/MiddleService';
import { useNavigate, Link } from 'react-router-dom';
import { countryCode } from "src/Service/service";

const ForgotPasswordMobile = () => {

    const alert = useAlert();
    const navigate = useNavigate();

    const [isLoading, setLoading] = useState(false);
    const [code, setCode] = useState(91);

    const {
        register,
        handleSubmit,
        watch,
        reset,
        formState: { errors }
    } = useForm();

    const onSubmit = async (data) => {
        const { contact, country_code } = data;

        setLoading(true);
        try {
            const response = await MiddleService.postData('user/sendForgotPasswodOtp', { "mobile_no": contact, country_code: parseInt(country_code) });

            if (response.status_code === 200) {
                const { user_id } = response;
                navigate('/forgotPasswordOTP', { state: { mobile_no: contact, user_id } });
                reset();
                // alert.success(response?.message);
            } else {
                alert.error(response);
            }
        } catch (error) {
            alert.error(error?.response?.data?.message || 'Something Went Wrong !!');
        }
        setLoading(false);
    };

    return (
        <>
            <div class="container-scroller">
                <div class="container-fluid page-body-wrapper full-page-wrapper">
                    <div class="content-wrapper d-flex align-items-center auth px-0">
                        <div class="row w-100 mx-0">
                            <div class="col-lg-4 mx-auto">
                                <div style={{ marginBottom: 5 }}>
                                    <Link to="/" className="createAccount mt-5" >
                                        <i class="fa fa-chevron-left" aria-hidden="true" style={{ marginRight: 5 }}></i>
                                        Back
                                    </Link>
                                </div>
                                <div class="auth-form-light text-left py-5 px-4 px-sm-5">
                                    <div class="brand-logo text-center">
                                        <img src="/images/Fruxinfo_Logo.png" alt="logo" />
                                    </div>
                                    <h6 class="font-weight-light">Enter Phone Number</h6>
                                    <form class="pt-3" onSubmit={handleSubmit(onSubmit)}>
                                        <div class="form-group">
                                            <div className="w-100 text-flex">
                                                <select
                                                    {...register("country_code", {
                                                        required: true,
                                                    })}
                                                    className="select-border" 
                                                    // onChange={(e) => {
                                                    //     setCode(e.target.value)
                                                    // }}
                                                    >
                                                    {countryCode && countryCode.map((item, index) => (
                                                        <option key={index} selected={item.code == 91 ? true : false} value={item.code}>+{item.code}</option>
                                                    ))}
                                                </select>
                                                <input
                                                    {...register("contact", {
                                                        required: true,
                                                    })}
                                                    // onChange={(e) => setNumber(e.target.value)}
                                                    class="form-control form-control-lg" id="exampleInputEmail1" placeholder="Enter phone number" />
                                                {/* <input
                                                    {...register("contact", {
                                                        required: 'Phone No is requried',
                                                        pattern: {
                                                            value: /^[0-9]{10}$/,
                                                            message: 'Invalid phone number',
                                                        },
                                                    })}
                                                    class="form-control form-control-lg" placeholder="Enter phone no" /> */}
                                            </div>
                                                {errors?.contact?.type === "required" && <p className="errorMessage">This field is required</p>}
                                        </div>
                                        <div class="mt-3 section-login">
                                            <button class="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn" disabled={isLoading}>
                                                {
                                                    isLoading ?
                                                        <>
                                                            <span class="spinner-border spinner-border-sm"></span> Loading...
                                                        </>
                                                        : 'Next'
                                                }
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPasswordMobile;